/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:479fd9d9-95e4-4503-90dd-c4cc4fb8f450",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_DjlhCNFVX",
    "aws_user_pools_web_client_id": "6l9nre7s6lslht7sma6gqab2rn",
    "oauth": {},
    "aws_cognito_login_mechanisms": [
        "EMAIL"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "ap-southeast-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "SeedsInSpaceTable-prod",
            "region": "ap-southeast-2"
        }
    ],
    "aws_user_files_s3_bucket": "seeds-in-space232625-prod",
    "aws_user_files_s3_bucket_region": "ap-southeast-2",
    "aws_cloud_logic_custom": [
        {
            "name": "SeedsInSpaceAPI",
            "endpoint": "https://6yi4yxo1gh.execute-api.ap-southeast-2.amazonaws.com/prod",
            "region": "ap-southeast-2"
        }
    ]
};


export default awsmobile;
